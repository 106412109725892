import type { TypographyProps } from '@mui/material';

import * as React from 'react';
import styled from '@emotion/styled';
import { useEditorHandlers } from './@editor';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import Container from '@mui/material/Container';

export interface TitleComponentProps extends TypographyProps {
  disableGutters?: boolean;
  component?: React.ElementType; // Todo: Missing from @mui https://github.com/mui/material-ui/issues/20373
}

const Typography = React.forwardRef<HTMLSpanElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const Root = styled(Container)(({ theme }) => ({
  borderBottomWidth: theme.theme_component_title_border_bottom_width
    ? `${theme.theme_component_title_border_bottom_width}px`
    : 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.theme_component_title_border_bottom_color || theme.palette.divider,
  color: theme.theme_component_title_color || theme.palette.text.primary,
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  textTransform: 'capitalize'
})) as typeof Container;

const TypographyStyled = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.theme_component_title_title_color || 'inherit',
  '& > a': {
    color: theme.theme_component_title_title_color || theme.palette.text.primary,
    '&:hover': {
      color: theme.theme_component_title_link_hover_color || theme.palette.action.hover,
    },
    '&:active': {
      color: theme.theme_component_title_link_active_color || theme.palette.action.active,
    },
  },
}));

export const Title = (props: TitleComponentProps) => {
  const { disableGutters, children, className, ...other } = props;
  const titleRef = React.useRef<HTMLSpanElement | null>(null);

  return (
    <Root
      maxWidth={false}
      disableGutters={disableGutters}
      className={className}
      {...useEditorHandlers('Title')}
    >
      <TypographyStyled ref={titleRef} {...other}>
        {children}
      </TypographyStyled>
    </Root>
  );
}
